import React, { useEffect, useState } from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import cogoToast from "cogo-toast";
import formatNumber from "../../../utils/formatNumber";
import {getUserReport, user} from "../../../api/app";
import DateRangePicker from "../../../components/common/DatePicker";

let currentDateRange = null;

const User = (props) => {

    document.title = "User";

    const token = localStorage.getItem('jwtToken');
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);

    getUserReport().then((response) => {
        if(!response.error) {
            $('#total-users').text(formatNumber(response.payload.total));
            $('#date-current').text(response.payload.date_current);
            $('#month-current').text(response.payload.month_current);
            $('#daily-active').text(formatNumber(response.payload.daily));
            $('#monthly-active').text(formatNumber(response.payload.monthly));
            $('#total-gender').text(`Male: ${formatNumber(response.payload.total_male)} --- Female: ${formatNumber(response.payload.total_female)}`);
        }
    });

    useEffect(() => {
        let table = $('#td-user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting User Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                const searchTerm = $('.dataTables_filter input').val();
                const regexSearch = { $regex: searchTerm, $options: "i" };

                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: searchTerm,
                        collection: "user",
                        search: {
                            $or: [
                                { first_name: regexSearch },
                                { last_name: regexSearch }
                            ]
                        },
                        filter: currentDateRange !== null ? {
                            "date.iso":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {},
                        pageSize: data.start,
                        format: 'json',
                        populate: "country",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.first_name)} ${capitalize(data.last_name)}`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.gender)
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY - HH:mm");
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_user"><i class="fa fa-eye"></i> View</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/user/${extract_td.raw._id}`);
        });

        table.on('click', '.delete_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.first_name)} ${capitalize(extract_td.raw.last_name)}?`);
            $('#delete-user').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                user("delete", data).then((result) => {
                    if(!result.error) {
                        getUserReport().then((response) => {
                            if(!response.error) {
                                hide();
                                $('#total-users').text(formatNumber(response.payload.total));
                                $('#date-current').text(response.payload.date_current);
                                $('#month-current').text(response.payload.month_current);
                                $('#daily-active').text(formatNumber(response.payload.daily));
                                $('#monthly-active').text(formatNumber(response.payload.monthly));
                                $('#total-gender').text(`Male: ${formatNumber(response.payload.total_male)} --- Female: ${formatNumber(response.payload.total_female)}`);
                                reloadTable();
                                cogoToast.success("User successfully removed.", {position: "top-right"});
                            }
                        });
                    } else {
                        hide();
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        $('#td-user tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Last Login:</td>' +
                '<td>' +
                d.raw.last_login.str +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Country:</td>' +
                '<td>' +
                capitalize(d.raw.country.name) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Device info:</td>' +
                '<td>'+capitalize(d.raw.device.mobile.brand_name)+', '+d.raw.device.mobile.model+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
            currentDateRange = null;
        }

    }, []);

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        $('#td-user').DataTable().ajax.reload(null, false);
    }

    const reloadTable = () => {
        $('#td-user').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Users</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Registered Users</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-users" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span id="total-gender" className="tx-11 tx-color-02 mg-b-0 mg-t-5">---</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Users Today</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="daily-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Users Monthly</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="monthly-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Users</h6>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect} />
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-user" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Gender</th>
                                        <th className="wd-20p">Date Created</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-user" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default User;
