import React, {useCallback, useEffect, useState, useRef} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import formatNumber from "../../../utils/formatNumber";
import {getWasteReport, getZones, pickup, waste} from "../../../api/app";
import cogoToast from "cogo-toast";
import jsonExcel from "../../../utils/jsonExcel";
import DateRangePicker from "../../../components/common/DatePicker";
import {Link} from "react-router-dom";
import ViewGMap from "../../../components/map/ViewGMap";

let currentDateRange = null;
let selectedArea = null;
let type = null;

const SpecialPickup = (props) => {

    document.title = "Special Pickup";

    const token = localStorage.getItem('jwtToken');
    const [activeLink, setActiveLink] = useState('All');
    const [openMap, setOpenMap] = useState(false);
    const [wasteId, setWasteId] = useState(null);
    const [coordinate, setCoordinate] = useState({});
    const [stateReady, setStateReady] = useState(false);
    const [refresh, setRefresh] = useState(null);

    const [zones, setZones] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [areaName, setAreaName] = useState("All Zones");
    const [subdivisionName, setSubdivisionName] = useState("Select");
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);

    useEffect(() => {
        getWasteReport("pickup").then((response) => {
            if(!response.error) {
                $('#total-waste').text(formatNumber(response.payload.total_wastes));
                $('#total-paid').text(response.payload.total_paid);
                $('#total-expired').text(response.payload.total_expired);
            }
        });
    }, [refresh]);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        let table = $('#td-waste').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            type: "pickup",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                            "date.iso":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {
                            type: "pickup",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry } }
                        },
                        format: 'json',
                        populate: "user product address.property",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<a href="#/user/${data.user._id}" class="text-dark">${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}</a>`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            expired : `<span class="badge badge-danger tx-white">Expired</span>`,
                            paid : `<span class="badge badge-success tx-white">Paid</span>`,
                            pending : `<span class="badge badge-primary tx-white">Pending</span>`,
                            awaiting : `<span class="badge badge-secondary tx-white">Awaiting</span>`,
                            credit : `<span class="badge badge-warning tx-white">Credit</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return `<span>${moment(data.date.iso).format("Do MMM, YYYY")}</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/user/${extract_td.raw._id}`);
        });

        $('#td-waste tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '<tr>' +
                '<td>Size:</td>' +
                '<td>' +
                capitalize(d.raw.size) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Note:</td>' +
                '<td>' +
                capitalize(d.raw.note) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Action:</td>' +
                '<td>'+`<nav class="nav nav-icon-only"><a href="#" data-target="${d.raw.address.location.coordinates}" class="nav-link view-map"><i class="fa fa-map"></i> View Map</a> ${d.raw.status === "pending" ? `<a href="#" data-id="${d.raw._id}" class="nav-link view-pickup"><i class="fa fa-pencil"></i> Accept</a> <a href="#" data-id="${d.raw._id}" class="nav-link decline-pickup"><i class="fa fa-trash"></i> Decline</a>` : `<span class="nav-link"><i class="fa fa-ban"></i> No Action</span>`}</nav>`+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        table.on('click', '.view-map', function(e) {
            e.preventDefault();
            let data = $(this).attr("data-target");

            let [lat, lng] = data.split(',').map(Number);

            setCoordinate({lat, lng});

            $("#modalMap").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', '.view-pickup', function(e) {
            e.preventDefault();

            initializeCollectorSearch();

            let data_id = $(this).attr("data-id");

            setWasteId(data_id);

            $('#pickup_form').parsley();
            $("#modalPickup").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.decline-pickup', function(e) {
            e.preventDefault();

            let data_id = $(this).attr("data-id");

            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: data_id,
                }

                waste("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Waste successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            table.destroy();
            currentDateRange = null;
            selectedArea = null;
            type = null;
        }

    }, []);

    const modalFilter = () => {

    }

    const exportToExcel = () => {
        const jsonData = [
            { name: 'John Doe', age: 30, email: 'john@example.com' },
            { name: 'Jane Smith', age: 25, email: 'jane@example.com' },
            // Add more data as needed
        ];

        jsonExcel({jsonData, fileName: `waste-report-${moment(new Date()).format("DD-MM-YYYY")}`, columnWidths: [30, 30, 50]});
    };

    const reloadTable = () => {
        setRefresh(Math.random());
        $('#td-waste').DataTable().ajax.reload(null, false);
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const initializeCollectorSearch = () => {
        let modalPickup = $('#modalPickup');
        $('#collector-select2').select2({
            placeholder: 'Select garbage collector or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalPickup,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "collector"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let amount = $('#amount').val();
        setStateReady(true);

        let data = {
            waste_id: wasteId,
            amount: amount,
            collector_id: $('#collector-select2').val()[0]
        };

        pickup("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalPickup", "pickup_form");
                reloadTable();
            }
        });
    }

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const onAreaSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setAreaName(area.name) : setAreaName("All Zones");
        area !== null ? setSubdivisions(area.subdivisions) : setSubdivisions([]);
        reloadTable();
    }

    const onSubdivisionSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setSubdivisionName(area.name) : setSubdivisionName("Select");
        reloadTable();
    }

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        $('#td-waste').DataTable().ajax.reload(null, false);
    }

    const handleNavClick = (linkName) => {
        setActiveLink(linkName);
        type = linkName === "All" ? null : linkName.toLowerCase();
        reloadTable();
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Wastes</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Special Pickup</h4>
                    </div>
                    <div className="d-none d-md-block">
                        {loading ? <button disabled className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {areaName}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    <a href="#" onClick={(e) => {e.preventDefault(); onAreaSelected(null)}} className="dropdown-item">All Zones</a>
                                    {zones.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onAreaSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span> }
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {subdivisions?.length ? subdivisionName : "Select"}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    {subdivisions?.length ? subdivisions.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onSubdivisionSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    }) : <span className="dropdown-item">Select Zone First</span>}
                                </div>
                            </span>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total
                                Wastes</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-waste" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Special Pickup</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total Paid</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-paid" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Paid Special Pickup</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Pending</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-expired" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Pending Special Pickup</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card card-crypto">
                            <div className="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
                                <nav className="nav nav-line">
                                    <Link to="#" className={`nav-link ${activeLink === 'All' ? 'active' : ''}`} onClick={() => handleNavClick('All')}>All</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Paid' ? 'active' : ''}`} onClick={() => handleNavClick('Paid')}>Paid</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Awaiting' ? 'active' : ''}`} onClick={() => handleNavClick('Awaiting')}>Awaiting</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Expired' ? 'active' : ''}`} onClick={() => handleNavClick('Expired')}>Expired</Link>
                                    <Link to="#" className={`nav-link ${activeLink === 'Cancelled' ? 'active' : ''}`} onClick={() => handleNavClick('Cancelled')}>Cancelled</Link>
                                </nav>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect} />
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-waste" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Area</th>
                                        <th className="wd-15p">Date Issued</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalMap" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                               aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="#" role="button"
                                                           className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                                           aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-2">View Map</h4>
                                <p className="tx-13 tx-color-02 mg-b-0">Map information.</p>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            {openMap ? <ViewGMap coordinate={coordinate}/> : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPickup" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalPickup", "pickup_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4
                                className="tx-18 tx-sm-20 mg-b-2">Special Pickup</h4>
                                <p className="tx-13 tx-color-02 mg-b-0">Special Pickup information.</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="pickup_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                <div id="collectorWrapper" className="form-group parsley-input">
                                    <div className="d-flex justify-content-between">
                                        <label>Garbage Collector<span className="tx-danger">*</span></label>
                                    </div>
                                    <select className="form-control" id="collector-select2" data-width="100%"
                                            multiple="multiple"
                                            data-parsley-class-handler="#collectorWrapper"
                                            data-parsley-errors-container="#collectorWrapper"
                                            autoComplete="off"
                                            required>
                                    </select>
                                </div>

                                <div id="amountWrapper" className="form-group parsley-input">
                                    <label>Amount<span className="tx-danger">*</span></label>
                                    <input id="amount" type="number" className="form-control"
                                           placeholder="Enter amount"
                                           autoComplete="none"
                                           data-parsley-class-handler="#amountWrapper" required/>
                                </div>

                                {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> :
                                    <button className="btn btn-success btn-block">Proceed</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SpecialPickup;
