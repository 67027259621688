import React, {useEffect, useState} from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "components/layout/PageError";
import AccessControl from "pages/authorized/settings/AccessControl";
import User from "pages/authorized/user/index";
import Region from "../../pages/authorized/settings/Region";
import Product from "../../pages/authorized/settings/Product";
import QRCode from "../../pages/authorized/settings/QRCode";
import Collector from "../../pages/authorized/collector/index";
import Transaction from "../../pages/authorized/transaction/index";
import Report from "../../pages/authorized/report/index";
import CollectorData from "../../pages/authorized/collector/Data";
import UserData from "../../pages/authorized/user/Data";
import PushNotification from "../../pages/authorized/settings/PushNotification";
import Subscription from "../../pages/authorized/waste/Subscription";
import SpecialPickup from "../../pages/authorized/waste/SpecialPickup";
import Fumigation from "../../pages/authorized/enquiry/Fumigation";
import Collection from "../../pages/authorized/general/Collection";
import Graph from "../../pages/authorized/Graph";
import Media from "../../pages/authorized/other/Media";
import LiveTrack from "../../pages/authorized/general/LiveTrack";
import {SocketProvider} from "../common/SocketContext";
import io from "socket.io-client";
import Finance from "../../pages/authorized/finance";
import Rate from "../../pages/authorized/general/Rate";
import Zone from "../../pages/authorized/settings/Zone";

const Private = () => {

    // const [userCount, setUserCount] = useState(0);
    //
    // useEffect(() => {
    //     const socket = io('http://127.0.0.1:3002');
    //
    //     socket.on('userCount', (count) => {
    //         console.log({connected: count})
    //         setUserCount(count);
    //     });
    //
    //     // Clean up the socket connection when the component unmounts
    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);

    return (
        <SocketProvider>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Graph} />
                <PrivateRoute exact path="/user" component={User} />
                <PrivateRoute exact path="/collection" component={Collection} />
                <PrivateRoute exact path="/error" component={PageError} />
                <PrivateRoute exact path="/region" component={Region} />
                <PrivateRoute exact path="/rate" component={Rate} />
                <PrivateRoute exact path="/product" component={Product} />
                <PrivateRoute exact path="/qrcode" component={QRCode} />
                <PrivateRoute exact path="/collector" component={Collector} />
                <PrivateRoute exact path="/finance" component={Finance} />
                <PrivateRoute exact path="/zone" component={Zone} />
                <PrivateRoute exact path="/report" component={Report} />
                <PrivateRoute exact path="/transaction" component={Transaction} />
                <PrivateRoute exact path="/enquires/fumigation" component={Fumigation} />
                <PrivateRoute exact path="/collector/:id" component={CollectorData} />
                <PrivateRoute exact path="/user/:id" component={UserData} />
                <PrivateRoute exact path="/notification" component={PushNotification} />
                <PrivateRoute exact path="/access_control" component={AccessControl} />
                <PrivateRoute exact path="/media" component={Media} />
                <PrivateRoute exact path="/track" component={LiveTrack} />
                <PrivateRoute exact path="/waste/subscription" component={Subscription} />
                <PrivateRoute exact path="/waste/special-pickup" component={SpecialPickup} />
                <Route component={PageError} />
            </Switch>
        </SocketProvider>
    )
};

export default Private;
